const onPurchaseDL = (bookings, experiences) => {
    function getRateById(entity, rateId) {
        for (const product of entity.products) {
            const rate = product.rates.find((rate) => rate.id === rateId);
            if (rate) {
                return rate;
            }
        }
        return null;
    }
    function getIsResidentPurchase(rate, exp) {
        const rateExp = getRateById(exp, rate.rate_id);
        return rateExp ? getIsResidentDL(rateExp.customer_types.customer_types) : '';
    }
    function getRateTypePurchase(rate, exp) {
        const rateExp = getRateById(exp, rate.rate_id);
        return rateExp ? getRateTypeDL(rateExp.customer_types.customer_types) : '';
    }

    function getIndexesBookings() {
        let result = [];
        let index = 0;
        for (let i = 0; i < bookings.length; i++) {
            result[i] = [];
            for (let j = 0; j < bookings[i].product_rates.length; j++) {
                result[i][j] = index;
                index = index + 1;
            }
        }
        return result;
    }

    function getRateNamePurchase(rate) {
        if (rate.customer_type_ids && rate.customer_type_ids.length > 0) {
            const customerTypes = rate.customer_type_ids.split(',');
            return customerTypes.map((customerTypeId) => getCustomerType(Number(customerTypeId))).join(', ');
        }
    }

    const indexBookings = getIndexesBookings();

    const items = bookings.flatMap((booking, i) => {
        const exp = experiences[booking.product.experience.id];
        const weekDaysAvailable = getDaysAvailable(exp);
        const product = exp.products.find((product) => product.id === booking.product.id);
        const productName = product.name_spa ? product?.name_spa.trim() : product?.name.trim();
        return booking.product_rates.map((rate, j) => (
            {
                item_variant: booking.product.id + "_"
                    + rate.id + " - "
                    + productName +
                    " [" + getRateNamePurchase(rate) + "]",
                ...setSelectExperienceDL(exp, indexBookings[i][j]),
                item_variant2: '',
                quantity: rate.qty,
                duracion_excursion: (exp.duration.hours.toString().padStart(2, '0') + ':'
                    + exp.duration.minutes.toString().padStart(2, '0')),
                idiomas_excursion: exp.languages.toString(),
                dias_disponibles: weekDaysAvailable,
                fecha_reserva: extractDateDL(booking.booking_date),
                hora_reserva: extractHourDL(booking.booking_date),
                residente: getIsResidentPurchase(rate, exp),
                tipo_tarifa: getRateTypePurchase(rate, exp),
                fecha_compra: extractDateDL(booking.order.created)
            })
        )
    }
    );

    function getPaymentMethod(payment_method) {
        if (typeof payment_method === 'undefined') {
            return '';
        }
        switch (payment_method) {
            case 'paypal':
                return 'Paypal';
            case 'credit_card':
                return 'Tarjeta';
            default:
                return payment_method;
        }
    }

    let promoCodes = [];
    bookings.flatMap((booking) => {
        if (booking.booking_codes) {
            let formattedCodes = booking.booking_codes.map((promo_code) => promo_code.name);
            promoCodes.push(...formattedCodes);
        }
    });
    promoCodes = [...new Set(promoCodes)];

    window.addDataLayer({
        event: "purchase",
        payment_type: getPaymentMethod(bookings[0].payment_method),
        customer_email: bookings[0].order.customer.email,
        ecommerce: {
            transaction_id: bookings[0].order.id,
            value: parseFloat(bookings[0].amount.total_amount).toFixed(2),
            tax: '0',
            shipping: '00.00',
            currency: "EUR",
            coupon: promoCodes.join(','),
            items: items
        },
    })
}
exports.onPurchaseDL = onPurchaseDL;

const onRemoveFromCartDL = (item, experiences) => {
    const product = experiences[item.product.experience.id].products.find((product) => product.id === item.product.id);
    const productName = product.name_spa ? product?.name_spa.trim() : product?.name.trim();
    window.addDataLayer({
        event: 'remove_from_cart',
        ecommerce: {
            items: item.rates.map((rate, index) => (
                {
                    item_variant: item.product.id + "_"
                        + rate?.rate?.id + " - "
                        + productName +
                        " [" + getRateName(rate?.rate) + "]",
                    ...setSelectExperienceDL(experiences[item.product.experience.id], index),
                    item_variant2: '',
                    quantity: rate.qty,
                    fecha_reserva: extractDateDL(item?.booking_date),
                    hora_reserva: extractHourDL(item?.booking_date),
                    residente: getIsResidentDL(rate?.rate?.rate_elements),
                    tipo_tarifa: getRateTypeDL(rate?.rate?.rate_elements),
                })
            )
        }
    });
}
exports.onRemoveFromCartDL = onRemoveFromCartDL;
const extractDateDL = (booking_date) => {
    const date = new Date(booking_date);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
}

const extractHourDL = (booking_date) => {
    const date = new Date(booking_date);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const result = `${hours}:${minutes}`;
    return result === '00:00' ? '' : result;
}
const getItemsCheckOutDL = (lineItem, lineItemRate, index, experience) => {
    const product = experience.products.find((product) => product.id === lineItem.product.id);
    const productName = product.name_spa ? product?.name_spa.trim() : product?.name.trim();
    return {
        item_variant: lineItem?.product?.id + "_"
            + lineItemRate?.rate?.id + " - "
            + productName +
            " [" + getRateName(lineItemRate?.rate) + "]",
        ...setSelectExperienceDL(experience, index),
        item_variant2: '',
        quantity: lineItemRate.qty,
        fecha_reserva: extractDateDL(lineItem?.booking_date),
        hora_reserva: extractHourDL(lineItem?.booking_date),
        residente: getIsResidentDL(lineItemRate?.rate?.rate_elements),
        tipo_tarifa: getRateTypeDL(lineItemRate?.rate?.rate_elements),
    };//
}

const getRateName = (rate) => {
    if (rate.rate_elements.length === 0) {
        return '';
    }
    return rate.rate_elements.map((rateElement) => getCustomerType(rateElement.id)).join(', ');
}

// Add Payment Info
const onAddPaymentInfoCartDL = (cart, experiences) => {
    function getPaymentMethod(payment_method) {
        return payment_method === 'redsys' ? 'Tarjeta' : payment_method;
    }

    const items = cart.line_items.map(
        (lineItem, index) => lineItem.rates.map(
            (lineItemRate, index) => getItemsCheckOutDL(
                lineItem,
                lineItemRate,
                index,
                experiences[lineItem.product.experience.id]
            )
        )
    ).flat()

    window.addDataLayer({
        event: "add_payment_info",
        payment_type: getPaymentMethod(cart?.payment_gateway?.payment_method),
        checkout_step: '6',
        ecommerce: {
            items: items
        }
    })
}
exports.onAddPaymentInfoCartDL = onAddPaymentInfoCartDL;

// Add Shipping Info
const onAddShippingInfoDL = (cart, experiences) => {
    const items = cart.line_items.map(
        (lineItem, index) => lineItem.rates.map(
            (lineItemRate, index) => getItemsCheckOutDL(
                lineItem,
                lineItemRate,
                index,
                experiences[lineItem.product.experience.id]
            )
        )).flat();
    window.addDataLayer({
        event: 'add_shipping_info',
        checkout_step: '5', // Esta variable recoge el paso del checkout.
        ecommerce: {
            items: items
        }
    });
}
exports.onAddShippingInfoDL = onAddShippingInfoDL;

const onBeginCheckoutStep1DL = (experience) => {
    window.addDataLayer({
        event: 'begin_checkout',
        checkout_step: '1',
        ecommerce: {
            items: [{
                ...setSelectExperienceDL(experience),
                dificultad_excursion: getDifficultExperience(experience.difficulty_id),
                quantity: 1,
            }]
        }
    });
}
exports.onBeginCheckoutStep1DL = onBeginCheckoutStep1DL;

const onBeginCheckoutStep2DL = (experience, data) => {
    const productName = data.product.name_spa ? data.product.name_spa.trim() : data.product.name.trim();
    window.addDataLayer({
        event: 'begin_checkout',
        checkout_step: '2',
        ecommerce: {
            items: [{
                item_variant: data.product.id + " - " + productName,
                ...setSelectExperienceDL(experience),
                dificultad_excursion: getDifficultExperience(experience.difficulty_id),
                quantity: 1,
                fechas_disponibles: data.availability,
            }]
        }
    });
}
exports.onBeginCheckoutStep2DL = onBeginCheckoutStep2DL;

const onBeginCheckoutStep3DL = (experience, data) => {
    const productName = data.product.name_spa ? data.product.name_spa.trim() : data.product.name.trim();
    window.addDataLayer({
        event: 'begin_checkout',
        checkout_step: '3',
        ecommerce: {
            items: [{
                item_variant: data.product.id + " - " + productName,
                ...setSelectExperienceDL(experience),
                dificultad_excursion: getDifficultExperience(experience.difficulty_id),
                quantity: 1,
                fechas_disponibles: data.availability,
            }]
        }
    });
}
exports.onBeginCheckoutStep3DL = onBeginCheckoutStep3DL;

const getIsResidentDL = (item) => {
    const customerType = item.find(
        (customerType) => customerType.id === 18
    );
    return customerType ? 'Si' : 'No';
}

const getDifficultExperience = (difficulty) => {
    switch (difficulty) {
        case 'low':
            return 'Baja';
        case 'medium':
            return 'Media';
        case 'high':
            return 'Alta';
        default:
            return '';
    }
}

const getRateTypeDL = (item) => {
    const rateType = item.find(
        (customerType) => (
            (customerType.group_id && customerType.group_id === 5)
            || (customerType.type && customerType.type === 5)
        )
    )

    return rateType ? getCustomerType(rateType.id) : '';
}

const onBeginCheckoutStep4DL = (experience, data) => {

    if (data.selection.session === 'day_wide') {
        onBeginCheckoutStep3DL(experience, data);
    }
    const productName = data.product.name_spa ? data.product.name_spa.trim() : data.product.name.trim();
    window.addDataLayer({
        event: 'begin_checkout',
        checkout_step: '4',
        ecommerce: {
            items: {
                item_variant: data.product.id + " - " + productName,
                ...setSelectExperienceDL(experience),
                dificultad_excursion: getDifficultExperience(experience.difficulty_id),
                quantity: 1,
                fechas_disponibles: data.availability,
                fecha_reserva: data.selection.date,
                hora_reserva: data.selection.session === 'day_wide' ? '' : data.selection.session,
            }
        }
    });
}
exports.onBeginCheckoutStep4DL = onBeginCheckoutStep4DL;

const onAddToCartDL = (experience, buttonSection) => {
    window.addDataLayer({
        event: 'add_to_cart',
        selected_button: buttonSection,
        ecommerce: {
            items: [{
                ...setSelectExperienceDL(experience),
                quantity: 1,
                dificultad_excursion: getDifficultExperience(experience.difficulty_id),
            }]
        }
    });
}
exports.onAddToCartDL = onAddToCartDL;

const onViewItemDL = (experience) => {
    window.addDataLayer({
        event: 'view_item',
        ecommerce: {
            items: [{
                ...setSelectExperienceDL(experience),
                dificultad_excursion: getDifficultExperience(experience.difficulty_id),
            }]
        }
    });
}
exports.onViewItemDL = onViewItemDL;

const onSelectItemDL = (experience, index) => {

    window.addDataLayer({
        event: 'select_item',
        ecommerce: {
            items: [{
                ...setSelectExperienceDL(experience, index),
            }]
        }
    });
}
exports.onSelectItemDL = onSelectItemDL;

const onViewItemListDL = (experiences) => {
    const items = experiences.map((experience, index) => (
        {
            ...setSelectExperienceDL(experience, index),
        }
    ));

    window.addDataLayer({
        event: 'view_item_list',
        ecommerce: {
            items: items
        }
    });
}
exports.onViewItemListDL = onViewItemListDL;

const setSelectExperienceDL = (experience, index) => {
    if (typeof experience.node !== 'undefined') {
        experience = experience.node;
    }

    let weekDaysAvailable = getDaysAvailable(experience);

    const lowestRate = experience.lowestRate ? experience.lowestRate : experience.lowest_rate;
    const experienceId = experience.experienceId ? experience.experienceId : experience.id;

    function getExperienceSubcategory(subcategory) {
        if (subcategory && subcategory.name_spa) {
            return experience.subcategory.name_spa.trim();
        } else if (subcategory && subcategory.name) {
            return experience.subcategory.name.trim();
        }
        return '';
    }
    const experienceName = experience.name_spa ? experience.name_spa.trim() : experience.name.trim();
    return {
        item_name: experienceId + ' - ' + experienceName,
        item_id: experienceId,
        price: Number(lowestRate.value),
        item_category: getExperienceSubcategory(experience.subcategory),
        item_list_name: experienceName,
        index: isNumber(index) ? index + 1 : 1,
        duracion_excursion: (experience.duration.hours.toString().padStart(2, '0') + ':'
            + experience.duration.minutes.toString().padStart(2, '0')),
        idiomas_excursion: experience.languages.toString(),
        dias_disponibles: weekDaysAvailable,
    }
}

const isNumber = n => (typeof (n) === 'number' || n instanceof Number);

const getDaysAvailable = (experience) => {
    function WeekDays(weekdays) {
        const availableWeekDays = {
            1: "Lunes",
            2: "Martes",
            4: "Miércoles",
            8: "Jueves",
            16: "Viernes",
            32: "Sábado",
            64: "Domingo",
        };

        const processWeekDays = (days) => {
            return Object.keys(availableWeekDays)
                .filter((key) => days & key)
                .map((key) => availableWeekDays[key]);
        };

        const weekdaysAvailable = processWeekDays(parseInt(weekdays, 2));

        if (weekdaysAvailable.length === 0) {
            return '';
        }
        return weekdaysAvailable.join(',');
    }

    if (typeof experience.products === 'undefined') {
        return '';
    }

    let weekDaysString = 'week_days'
    if (experience.products[0].weekDays) {
        weekDaysString = 'weekDays';
    }

    let weekDaysSum = Array(experience.products[0][weekDaysString].length).fill(0);

    experience.products.forEach(item => {
        let weekDays = item[weekDaysString].split('');
        weekDays.forEach((value, index) => {
            if (value === '1') {
                weekDaysSum[index] = 1;
            }
        });
    });

    return WeekDays(weekDaysSum.join(''));
}

const getCustomerType = (id) => {
    const result = customerTypes.find((customerType) => customerType.id === id);
    return result ? result.content : '';
}

const customerTypes = [
    {
        "id": 8,
        "content": "Pareja"
    },
    {
        "id": 9,
        "content": "Amigos"
    },
    {
        "id": 26,
        "content": "Familias"
    },
    {
        "id": 27,
        "content": "Para empresas"
    },
    {
        "id": 41,
        "content": "Solo"
    },
    {
        "id": 71,
        "content": "Grupo"
    },
    {
        "id": 88,
        "content": "Amigos"
    },
    {
        "id": 89,
        "content": "Solo"
    },
    {
        "id": 5,
        "content": "Primera vez"
    },
    {
        "id": 6,
        "content": "De vuelta a la isla"
    },
    {
        "id": 28,
        "content": "Auténticas"
    },
    {
        "id": 11,
        "content": "Lujo"
    },
    {
        "id": 12,
        "content": "Económico"
    },
    {
        "id": 62,
        "content": "Verde"
    },
    {
        "id": 63,
        "content": "Naranja"
    },
    {
        "id": 98,
        "content": "Hora extra de servicio"
    },
    {
        "id": 104,
        "content": "Grupo A"
    },
    {
        "id": 105,
        "content": "Grupo B"
    },
    {
        "id": 106,
        "content": "Grupo C"
    },
    {
        "id": 107,
        "content": "Grupo D"
    },
    {
        "id": 108,
        "content": "Grupo E (7PL)"
    },
    {
        "id": 109,
        "content": "Grupo F (9PL)"
    },
    {
        "id": 110,
        "content": "Grupo G (9PL)"
    },
    {
        "id": 111,
        "content": "Todo riesgo"
    },
    {
        "id": 112,
        "content": "Aeropuerto"
    },
    {
        "id": 113,
        "content": "Fuera horario"
    },
    {
        "id": 119,
        "content": "Niño 6-12 años"
    },
    {
        "id": 10,
        "content": "Guía Turístico"
    },
    {
        "id": 13,
        "content": "Bebe"
    },
    {
        "id": 14,
        "content": "Niño"
    },
    {
        "id": 15,
        "content": "Adolescente"
    },
    {
        "id": 16,
        "content": "Adulto"
    },
    {
        "id": 17,
        "content": "Senior"
    },
    {
        "id": 42,
        "content": "Estudiante"
    },
    {
        "id": 48,
        "content": "Niño 3-4 años"
    },
    {
        "id": 49,
        "content": "Niño mayor de 12 años"
    },
    {
        "id": 50,
        "content": "Niño de 2-5 años"
    },
    {
        "id": 51,
        "content": "Niño de 7 a 12 años"
    },
    {
        "id": 52,
        "content": "Grupos de 5 personas"
    },
    {
        "id": 53,
        "content": "Adulto adicional"
    },
    {
        "id": 54,
        "content": "Jubilado"
    },
    {
        "id": 65,
        "content": "Cabaña"
    },
    {
        "id": 68,
        "content": "Villa"
    },
    {
        "id": 74,
        "content": "Grupo"
    },
    {
        "id": 79,
        "content": "Niño 8-12 años"
    },
    {
        "id": 82,
        "content": "Niño 5-7 años"
    },
    {
        "id": 85,
        "content": "Niño 4-11 años"
    },
    {
        "id": 86,
        "content": "Grupo 8 hrs"
    },
    {
        "id": 87,
        "content": "Niño grupo"
    },
    {
        "id": 90,
        "content": "Niño 2-12 años"
    },
    {
        "id": 93,
        "content": "Niño 4-12 años"
    },
    {
        "id": 94,
        "content": "Niño 6-11 años"
    },
    {
        "id": 96,
        "content": "Niños de 0 a 5 años"
    },
    {
        "id": 97,
        "content": "Niños hasta 8 años"
    },
    {
        "id": 100,
        "content": "Niño 3-11 años"
    },
    {
        "id": 101,
        "content": "Niño 5-12 años"
    },
    {
        "id": 102,
        "content": "Niño 9-12 años"
    },
    {
        "id": 103,
        "content": "Niño 6-13 años"
    },
    {
        "id": 114,
        "content": "Niño 0-9 años"
    },
    {
        "id": 115,
        "content": "Niño 10-12 años"
    },
    {
        "id": 116,
        "content": "Niño 3-13 años"
    },
    {
        "id": 117,
        "content": "Niño 8-16 años"
    },
    {
        "id": 118,
        "content": "Niño 6-12 años"
    },
    {
        "id": 120,
        "content": "Niño 3-9 años"
    },
    {
        "id": 121,
        "content": "Sesión"
    },
    {
        "id": 122,
        "content": "Niños hasta 10 años"
    },
    {
        "id": 123,
        "content": "Niños hasta 12 años"
    },
    {
        "id": 124,
        "content": "Menores de 12"
    },
    {
        "id": 125,
        "content": "Niño 6-13 años"
    },
    {
        "id": 126,
        "content": "Niños hasta 13 años"
    },
    {
        "id": 127,
        "content": "Niños de 0 a 4 años"
    },
    {
        "id": 131,
        "content": "Niño de 5 a 13 años"
    },
    {
        "id": 132,
        "content": "Movilidad reducida"
    },
    {
        "id": 133,
        "content": "Hasta 2 personas"
    },
    {
        "id": 134,
        "content": "Hasta 4 personas"
    },
    {
        "id": 135,
        "content": "Persona adicional"
    },
    {
        "id": 136,
        "content": "Senderista"
    },
    {
        "id": 137,
        "content": "Tipo1"
    },
    {
        "id": 138,
        "content": "General"
    },
    {
        "id": 139,
        "content": "Vehículo de hasta 6 personas"
    },
    {
        "id": 140,
        "content": "Tipo2"
    },
    {
        "id": 141,
        "content": "Tipo3"
    },
    {
        "id": 18,
        "content": "Residente"
    },
    {
        "id": 19,
        "content": "No residente"
    },
    {
        "id": 30,
        "content": "Comer"
    },
    {
        "id": 31,
        "content": "Beber"
    },
    {
        "id": 32,
        "content": "Aprender"
    },
    {
        "id": 37,
        "content": "Relax"
    },
    {
        "id": 38,
        "content": "Activo"
    },
    {
        "id": 39,
        "content": "Adrenalina"
    },
    {
        "id": 40,
        "content": "Explorar"
    },
    {
        "id": 43,
        "content": "Regalar"
    },
    {
        "id": 44,
        "content": "Disfrutar"
    },
    {
        "id": 46,
        "content": "Compras"
    },
    {
        "id": 33,
        "content": "Excursión turística"
    },
    {
        "id": 34,
        "content": "Grupo reducido"
    },
    {
        "id": 36,
        "content": "Por mi cuenta"
    },
    {
        "id": 45,
        "content": "Cruceros"
    },
    {
        "id": 47,
        "content": "VIP"
    },
    {
        "id": 99,
        "content": "Picnic"
    },
    {
        "id": 55,
        "content": "Invitado Oro"
    },
    {
        "id": 56,
        "content": "Invitado Vip"
    },
    {
        "id": 57,
        "content": "Invitado Plus"
    },
    {
        "id": 58,
        "content": "Tarifa Amiga"
    },
    {
        "id": 92,
        "content": "Con Descuento"
    },
    {
        "id": 95,
        "content": "Tarifa Especial"
    },
    {
        "id": 59,
        "content": "Tarifa Colectivos"
    },
    {
        "id": 60,
        "content": "Tarifa grupos"
    },
    {
        "id": 61,
        "content": "Tarifa montañero"
    },
    {
        "id": 91,
        "content": "Tarifa grupo directo"
    }
];
